import React from 'react';
import PropTypes from 'prop-types';
import { ContactPanel, Masthead } from 'molecules';

import 'scss/main.scss';
import { Shell } from 'layout';

const defaultProps = {
    className: '',
};

const propTypes = {
    className: PropTypes.string,
};

function RPostConfirmationPage({
    location: {
        pathname,
    },
}) {
    return (
        <Shell
            hideFooter={true}
            hideNav={true}
            pathname={pathname}
            seoTitle="RPost Confirmation"
        >
            <main className="l-grid__main" id="main-content">
                <Masthead
                    image={{
                        alt: "",
                        dimensions: {
                            height: 1080,
                            width: 1920,
                        },
                        url: "https://images.prismic.io/oxbury-fs-plc/4aefeb53-4a3d-490c-9f11-a91b0565ab98_savings+fields.jpg?auto=compress,format"
                    }}
                    strapline="RPost confirmation"
                    title="You have successfully submitted your document"
                />
                <ContactPanel />
            </main>
        </Shell>
    );
}

RPostConfirmationPage.propTypes = propTypes;
RPostConfirmationPage.defaultProps = defaultProps;

export default RPostConfirmationPage;
